import React from 'react';

import NewsArticle from '../../../components/news-article/news-article.component'

const Article = () => (
  <NewsArticle>
    <h1 className='page-title'>HSBC UK cuts mortgage rates across the board by up to 0.40%</h1>
    <p>HSBC UK has reduced mortgage rates across all its LTVs, with rates slashed by up to 0.40%, the bank announced today.</p>
    <p>Highlights include:</p>
    <ul>
      <li>75% LTV 5 year fixed rate (£999 fee) cut to 1.64% and becomes the lowest rate available at that level;</li>
      <li>90% LTV 2 year fixed rate (no fee) reduced by 0.40% to 3.59%;</li>
      <li>90% LTV 5 year fixed rate (no fee) down by 0.40% to 3.74%;</li>
      <li>2 year Tracker – 70% and 75% LTV reduced by 0.40% to 1.69%; and</li>
      <li>60% LTV 2 year fixed rate (£999 fee) down by 0.05% to 1.19%.</li>
    </ul>
    <p>Michelle Andrews, HSBC UK’s Head of Buying a Home, said: “Despite the obvious challenges, the housing and mortgage markets are quite lively. This rate cut by us, with eleven mortgages reduced by 0.30% or 0.40%, including those at 90% LTV, means getting onto or up the property ladder with HSBC UK, has become more affordable.”</p>
  </NewsArticle>
)

export default Article
